.MainBanner{
    background-image: url('../images/banner.jpg');
    background-color: black;
    background-position: center center;
    background-size: cover;
    min-height: 100vh;
}
.MainBanner .row {
    display: flex;
    justify-content: center;
    align-items: center;
}
.MainBanner .title {
    color: #085496;
    font-weight: 700;
}
.MainBanner .subtitle {
    color: #0c64c0;
}
.MainBanner .Content {
    padding: 40px;
}
.text-underline {
    text-decoration: underline;
}
/* .formation-souhaitee fieldset {
    letter-spacing: 2.00938em !important;
}
.niveau-souhaitee fieldset {
    letter-spacing: 1.5em !important;
}
.countrySelect fieldset {
    letter-spacing: 0.1em !important;
} */
/* Version Mobile  */
@media only screen and (max-width: 600px) {
    .MainBanner {
        height: auto;
    }

}